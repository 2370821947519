// pages/404.tsx
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'
import { useAuth } from 'hooks/useAuth'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Error404Image from 'public/error404.png'

const NotFoundPage = () => {
  const router = useRouter()
  const { user, onLogout } = useAuth()
  const customMessage = router.query?.message?.toString() || ''

  const redirectToHomePage = () => {
    router.push('/')
  }

  return (
    <>
      <div
        className={cx(
          'flex flex-col justify-center items-center gap-4',
          user?.id ? 'h-[calc(100vh-12.375rem)]' : 'h-screen'
        )}>
        <Image src={Error404Image} alt="Page not found <image>" />
        <Typography variant={Variant.Display2} type="semibold">
          Oops! Page not found
        </Typography>
        <Typography variant={Variant.Heading2} type="regular" className="text-center">
          {customMessage || `Sorry, the page you are requested is not found. You must refresh the page.`}
        </Typography>
        <div className="flex justify-center gap-16">
          <Button onClick={redirectToHomePage}>Go Back To Home</Button>
          <Button variant={ButtonVariant.Secondary} onClick={onLogout}>
            Logout
          </Button>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
